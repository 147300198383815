import { reactive, ref, toRefs, onMounted } from 'vue'
export const useQueryView = (formRef, { getListPage, doQueryOnMounted = true, onReset = () => {} } = {}) => {
  const dataSource = ref([])
  const pageOptions = reactive({
    pageNo: 1,
    pageSize: 10,
    total: 0,
  })
  const loading = ref(false)
  const handleTableChange = async (pagination) => {
    const { current, pageSize } = pagination
    pageOptions.pageNo = current
    pageOptions.pageSize = pageSize
    doQuery()
  }
  const resetAndQuery = async () => {
    const form = formRef.value?.form
    if (form) {
      form.resetFields()
    }
    onReset()
    doQuery(1)
  }
  const getRowKey = (row, index) => {
    return index
  }
  const doQuery = async (pageNo = pageOptions.pageNo, pageSize = pageOptions.pageSize) => {
    pageOptions.pageNo = pageNo
    pageOptions.pageSize = pageSize
    loading.value = true
    try {
      const form = formRef.value?.form
      const _pageOptions = await getListPage(pageOptions, form ? form.getFieldsValue() : {})
      dataSource.value = _pageOptions.dataSource
      if (_pageOptions.pageNo) {
        pageOptions.pageNo = _pageOptions.pageNo
      }
      if (_pageOptions.pageSize) {
        pageOptions.pageSize = _pageOptions.pageSize
      }
      pageOptions.total = _pageOptions.total
    } catch (e) {
      console.error(e)
    }
    loading.value = false
  }

  onMounted(async () => {
    if (doQueryOnMounted) {
      doQuery()
    }
  })

  return {
    ...toRefs(pageOptions),
    dataSource,
    loading,
    handleTableChange,
    resetAndQuery,
    doQuery,
    getRowKey,
  }
}
