export const safeSetFieldsValue = (form, data, { emptyAsUndefined = true } = {}) => {
  Promise.resolve().then(() => {
    const fields = form.getFieldsValue()
    let initData = {}
    for (let i in fields) {
      if (i in data) {
        let formItem = data[i]
        if (emptyAsUndefined) {
          if (formItem === null || formItem === '') {
            formItem = undefined
          }
        }
        initData[i] = formItem
      }
    }
    form.setFieldsValue(initData)
    for (const i in form.instances) {
      let f = form.instances[i]
      if (f.$options._componentTag === 'PictureCardListUpload') {
        f.resetFileList()
      }
    }
  })
}
